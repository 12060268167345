<template>
  <div class="wood">
    <img src="/assets/wood.webp" alt="" class="w-100">
    <img src="/assets/branch.webp" class="left" alt="" v-if="branch == 'left'">
    <img src="/assets/branch.webp" class="right" alt="" v-if="branch == 'right'">
  </div>
</template>

<script>
export default {
  name: 'Wood',
  props: {
    branch: String
  }
}
</script>


<style scoped lang="scss">
  .wood{
    position: relative;
    z-index: 4;
    
    img.left{
      @media(max-width: 1600px){
        display:none;
      }
      position: absolute;
      left: 0;
      transform: rotate(180deg) translateY(725px) translateX(140px);
      @media(min-width:1800px){
        &{
          transform: rotate(180deg) translateY(725px) translateX(0px);
        }
      }
    }



    img.right{
      @media(max-width: 1600px){
        display:none;
      }
      position: absolute;
      right: 0;
      transform: translateY(-700px) translateX(0px);
      @media(min-width:1800px){
        &{
          transform:ranslateY(-725px);
        }
      }
    }
  }
</style>
