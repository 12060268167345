<template>
  <div id="FixedCallback">
    <div class="callback-bt" @click.stop="$parent.show_modalorder(`Заказ обратного звонка`)">
      <div class="text-call">
          <svg data-v-75071f03="" viewBox="0 0 405.333 405.333" class="mr-3"><use data-v-75071f03="" xlink:href="#i-phone"><symbol data-v-75071f03="" id="i-phone" viewBox="0 0 405.333 405.333"><path data-v-75071f03="" d="M373.333,266.88c-25.003,0-49.493-3.904-72.704-11.563c-11.328-3.904-24.192-0.896-31.637,6.699l-46.016,34.752c-52.8-28.181-86.592-61.952-114.389-114.368l33.813-44.928c8.512-8.512,11.563-20.971,7.915-32.64C142.592,81.472,138.667,56.96,138.667,32c0-17.643-14.357-32-32-32H32C14.357,0,0,14.357,0,32c0,205.845,167.488,373.333,373.333,373.333c17.643,0,32-14.357,32-32V298.88C405.333,281.237,390.976,266.88,373.333,266.88z"></path></symbol></use></svg>
          <span>Заказать<br>звонок</span>
      </div>      
    </div>

  </div>
</template>

<script>
export default {
  name: 'FixedCallback',
  props: {
    email: String,
    phone: String,
    opened_menu: Boolean
  }
}
</script>


<style scoped lang="scss">

.callback-bt {
        background:#e8fc00;; //#38a3fd;
        border:2px solid #222405; //#38a3fd;
        border-radius:50%;
        box-shadow:0 8px 10px rgba(178, 189, 48, 0.3); //rgba(232, 252, 0,0.3);
        cursor:pointer; 
        height:68px;
        text-align:center;
        width:68px;
        position: fixed;
        right: 8%;
        bottom: 18%;
        z-index:999;
        transition:.3s;
        -webkit-animation:hoverWave linear 1s infinite;
        animation:hoverWave linear 1s infinite;



  @media (max-width: 768px){
    &{
      transform: scale(0.7);      
    }

  }
}

.callback-bt .text-call{
        height:68px;
        width:68px;        
        border-radius:50%;
        position:relative;
        overflow:hidden;
}

.callback-bt .text-call span {
    text-align: center;
    color:#222405;//#38a3fd;
    opacity: 0;
    font-size: 0;
        position:absolute;
        right: 7px;
        top: 22px;
    line-height: 14px;
        font-weight: 600;
    text-transform: uppercase;
    transition: opacity .3s linear;
    font-family: 'montserrat', Arial, Helvetica, sans-serif;
}

.callback-bt .text-call:hover span {
    opacity: 1;
        font-size: 11px;
}
.callback-bt:hover svg {
    display:none;
}

.callback-bt:hover {
        z-index:999;
        background:#fff;
        color:transparent;
        transition:.3s;
}
/*
.callback-bt:hover i {
        color:#38a3fd;
        font-size:40px;
        transition:.3s;
}*/

.callback-bt svg {
        fill:#222405;
        transition:.3s;
        transition: .5s ease-in-out;
        width: 30px;
        margin-top: 17px;
        margin-left: 10px;
}

.callback-bt svg  {
    animation: 1200ms ease 0s normal none 1 running shake;
        animation-iteration-count: infinite;
        -webkit-animation: 1200ms ease 0s normal none 1 running shake;
        -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes hoverWave {
        0% {
        box-shadow:0 8px 10px rgba(232, 252, 0,0.3),0 0 0 0 rgba(232, 252, 0,0.2),0 0 0 0 rgba(232, 252, 0,0.2)
}
40% {
        box-shadow:0 8px 10px rgba(232, 252, 0,0.3),0 0 0 15px rgba(232, 252, 0,0.2),0 0 0 0 rgba(232, 252, 0,0.2)
}
80% {
        box-shadow:0 8px 10px rgba(232, 252, 0,0.3),0 0 0 30px rgba(232, 252, 0,0),0 0 0 26.7px rgba(232, 252, 0,0.067)
}
100% {
        box-shadow:0 8px 10px rgba(232, 252, 0,0.3),0 0 0 30px rgba(232, 252, 0,0),0 0 0 40px rgba(232, 252, 0,0.0)
}
}@keyframes hoverWave {
        0% {
        box-shadow:0 8px 10px rgba(232, 252, 0,0.3),0 0 0 0 rgba(232, 252, 0,0.2),0 0 0 0 rgba(232, 252, 0,0.2)
}
40% {
        box-shadow:0 8px 10px rgba(232, 252, 0,0.3),0 0 0 15px rgba(232, 252, 0,0.2),0 0 0 0 rgba(232, 252, 0,0.2)
}
80% {
        box-shadow:0 8px 10px rgba(232, 252, 0,0.3),0 0 0 30px rgba(232, 252, 0,0),0 0 0 26.7px rgba(232, 252, 0,0.067)
}
100% {
        box-shadow:0 8px 10px rgba(232, 252, 0,0.3),0 0 0 30px rgba(232, 252, 0,0),0 0 0 40px rgba(232, 252, 0,0.0)
}
}

/* animations icon */

@keyframes shake {
0% {
    transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
}
10% {
    transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
}
20% {
    transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
}
30% {
    transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
}
40% {
    transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
}
50% {
    transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
}
60% {
    transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
}
70% {
    transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
}
80% {
    transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
}
90% {
    transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
}
100% {
    transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
}
}

@-webkit-keyframes shake {
0% {
    transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
}
10% {
    transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
}
20% {
    transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
}
30% {
    transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
}
40% {
    transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
}
50% {
    transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
}
60% {
    transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
}
70% {
    transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
}
80% {
    transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
}
90% {
    transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
}
100% {
    transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
}
}



#app.on_modal_opened{
    .callback-bt{
        transform: translateX(40px) translateY(30px) scale(1.7);
        z-index: 1200;    
        @media(max-width: 768px){
            &{
                transform: translateX(10px) translateY(50px) scale(1);
            }
        }    
    }
}
</style>
