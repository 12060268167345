<template>
  <div class="catalogitem" tabindex="-1">
    <CoolLightBox :items="photo_gallery.items" :index="photo_gallery.index" :loop="false" @close="photo_gallery.index = null"></CoolLightBox>
    <div class="row">
      <div 
        class="col-6 pt-2 col-md-4 col-lg-3 col-xl mb-2 item mw-262px" 
        v-for="(v, k) in items" 
        :key="k" 
        @click="open_photo_gallery(v)"
      >
          <p class="h5 font-weight-normal" v-text="`${v.name}`"></p>
          <div class="d-table">
            <div class="d-table-row">
              <div class="d-table-cell no-wrap small">Длина:</div>
              <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.length"></span> м.</div>
            </div>
            <div class="d-table-row">
              <div class="d-table-cell no-wrap small">Ширина:</div>
              <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.width"></span> м.</div>
            </div>
            <div class="d-table-row">
              <div class="d-table-cell no-wrap small">Глубина:</div>
              <div class="d-table-cell no-wrap w-100 small pl-2">
                <template v-if="v.depth_start == v.depth_end">
                  <span v-text="v.depth_start"></span>
                </template>
                <template v-else>
                  <span v-text="v.depth_start"></span>
                  <img class="bottom_drop mx-1" src="/assets/bottom_drop.png" alt=" - ">
                  <span v-text="v.depth_end"></span>
                </template>
              </div>
            </div>
            <div class="d-table-row">
              <div class="d-table-cell no-wrap small">Объем:</div>
              <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.volume"></span> м.<sup>3</sup></div>
            </div>
          </div>
        <p class="no-wrap">
          <span v-text="$parent.$parent.number_format(v.price)"></span> р.
        </p>
        <img alt="" class="w-100" :src="`assets/otherCatalog/${v.file}/render_thm.png`">
      </div>

    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'CatalogItem',
  components: {
    CoolLightBox
  },
  props: {
    items: Array
  },
  data: function(){
    return {
      title: null,
      active: 0,
      render_index: null,
      photo_gallery: {
        items:[],
        index:null
      }
    }
  },
  methods:{
    open_photo_gallery(v){
      this.photo_gallery.items = []
      this.photo_gallery.items.push(`/assets/otherCatalog/${v.file}/render.png`)
      this.photo_gallery.index = 0
    }
  }
}
</script>


<style scoped lang="scss">
.modal-content{
  background-color: #1B3036;
  margin-bottom: 20px;
  max-width: 100%;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  overflow: visible;
  box-shadow: 0 0 20px 0 #1B3036;
  padding: 10px 16px;
  @media(min-width:992px){
    &{
      padding: 60px 96px;
    }
  }
}

input{
  transition: border-color 0.3s linear;
  box-sizing: border-box;
  width: 100%;
  display: block;
  color: #3b5058;
  margin: 0;
  border: 1px solid #bac3cb;
  border-radius: 100px;
  outline: 0;
  height: 52px;
  line-height: 52px;
  padding: 0 30px;
  background: #fff;
  &.has-error{
    border: 2px solid #fb0000;
  }
  &::placeholder {
    transition: color .3s linear;
    color: #6c919f;
  }
}

.modal-header, .modal-footer{
  border:none;
}

.privacy{
  font-size: 0.75rem;
  color: #69838a;
  line-height: 1;
}

.btn-close {
    outline: none;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #fff;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    &:hover {
      color: #ddd;
      text-decoration: none;
      opacity: .75;
    }
}


.item{
  cursor:pointer;
  transition: all ease .15s;
  &:hover{
    border-radius: 5px;
    background-color: #59b6d31c;
  }
  &.active{
    cursor:default;
    border: 1px solid #76d9f3;
    //padding: .5rem;
    border-radius: 5px;
    background-color: rgba(81, 169, 199, 0.2);
  }
}

.all_colors{
  width:58px;
  height:58px;
  padding:0.25rem;
  cursor: pointer;
  transition: all ease .3s;
  &.active{
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 8px #51a9c7);
  }
}

.mw-262px{
  min-width: 262px;
  max-width: 262px;
}

.bottom_drop{
  width: 30px;
  height: auto;
}
</style>
