<template>
  <div id="catalog" class="container py-5" v-if="Object.keys(catalog).length">
    <!-- <CoolLightBox :items="gallery.items" :index="gallery.index" @close="gallery.index = null" :loop="true"></CoolLightBox> -->
    <div class="row my-4 my-xl-5"  v-for="(v, k) in catalog" :key="k" :data-index="k">
        <div class="col-12">
          <p class="h2 font-weight-normal" v-text="`Производитель San Juan, серия ${v.series_name}`"></p>
        </div>
        <div class="col-12">
          <CatalogItem :items="v.items"></CatalogItem>
        </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CatalogItem from './CatalogItem.vue'
//import CoolLightBox from 'vue-cool-lightbox'
//import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'Catalog',
  components: {
    CatalogItem
    //CoolLightBox
  },
  props: {},
  data: () => {
    return {
      catalog: {},
      gallery: {
        index: null,
        items: ['/assets/pools/luxor.png', '/assets/pools/luxor.png']
      }
    }
  },
  methods:{
    open_gallery: function(file_name, cnt_imgs){
      this.gallery.items = []
      for (let i = 1; i <= cnt_imgs; i++){
        this.gallery.items.push(`/assets/pools/${file_name}/${i}.jpg`)
      }
      this.gallery.items.push(`/assets/pools/${file_name}.png`)
      this.gallery.index = 0
    },
    get_catalog: function(){

      //const devUrl = `http://192.168.1.100/composite` // !!!

      //axios.get(devUrl + '/catalog/').then((resp)=>{
      axios.get('/catalog/').then((resp)=>{
        this.$set(this, 'catalog', resp.data)
        this.$emit('set_catalog', resp.data)
      })
    }
  },
  created(){
    this.get_catalog()
  }
}
</script>


<style scoped lang="scss">
  #catalog{

      .render_wr{
        position: relative;
        .render{
          cursor: pointer;
        }
        svg{
          position: absolute;
          fill: #E8FC00;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 30%;
          height: 30%;
          transition: opacity .2s linear,transform .2s linear;
          opacity: 0;
          transform: scale(0);
          cursor: pointer;
        }  
        &:hover{
          svg{
            opacity: 1;
            transform: scale(1);            
          }

        }
      }







      @media(max-width:768px){
        .border-right-delimiter{
          position: relative;
          &::after{
            position: absolute;
            left: 0;
            right: 0;
            content: '';
            border-bottom: 1px solid #3F6E7D;
            margin-top: 1.5rem;
            } 
          }  
      }

      @media(min-width:768px) and (max-width:992px){
        .border-right-delimiter{
          position: relative;
          &::after{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            content: '';
            border-left: 1px solid #3F6E7D;
            } 
          } 
        .border-right-delimiter:nth-child(2n){
          position: relative;
          &::after{
            border: none;
            } 
          }   
      }

      @media(min-width:992px){
        .border-right-delimiter{
          position: relative;
          &::after{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            content: '';
            border-left: 2px solid #3F6E7D;
            } 
          } 
        .border-right-delimiter:nth-child(3n+3){
          position: relative;
          &::after{
            border: none;
            } 
          }      
      }

}
</style>
