<template>
  <div id="footer">
    <div class="container p-relative">
      <div class="row py-4 py-md-5 g-0">
        <div class="col-10 col-xl-4 col-lg-5 col-md-6">
          <div class="d-flex flex-row">
            <img src="/assets/logo.png" class="logo mr-2" alt="">
            <div class="logo_text text-white">
              <p class="mb-0 mt-3 h4 font-weight-bold ">Магазин бассейнов</p>
              <p class="font-weight-bold">От завода-производителя</p>
            </div>            
          </div>
          <p class="mt-2 mb-0 small">
            <a href="#" @click.prevent="$parent.modals.modalprivacy.show()">Политика конфиденциальности</a>
          </p>
          <p class="small blue-muted">ООО «Полигрупп», 2015 — 2021</p>
        </div>
        <div class="col-12 col-xl-8 col-lg-7 col-md-6">
          <ul class="row list-unstyled">
            <li class="col-12 col-md-6"><a class="menu" href="#" @click="$parent.scroll('header')">Главная</a></li>
            <li class="col-12 col-md-6"><a class="menu" href="#" @click="$parent.scroll('catalog')">Каталог</a></li>
            <li class="col-12 col-md-6"><a class="menu" href="#" @click="$parent.scroll('constructionsteps')">Монтаж</a></li>
            <li class="col-12 col-md-6"><a class="menu" href="#" @click="$parent.scroll('quiz')">Подбор бассейна</a></li>
            <li class="col-12 col-md-6"><a class="menu" href="#" @click="$parent.scroll('advantages')">Наши преимущества</a></li>
            <li class="col-12 col-md-6"><a class="menu" href="#" @click="$parent.scroll('photogallery')">Галерея</a></li>
            <li class="col-12 col-md-6"><a class="menu" href="#" @click="$parent.scroll('certs')">Сертификаты</a></li>
            <li class="col-12 col-md-6"><a class="menu" href="#" @click="$parent.scroll('contacts')">Контакты</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    email: String,
    phone: String,
    opened_menu: Boolean
  }
}
</script>


<style scoped lang="scss">
  #footer{
    a{
      color: #6C919F;
    }

    a.menu{
      color: rgb(59, 137, 168);
      font-size: 18px;
      text-decoration: none;
      &:hover{
        color: #fff;
        transition: color .1s linear;
      }
    }


    .blue-muted{
      color: #6C919F;
    }

    .logo{
      width:80px;
      height: 80px;
      @media(max-width: 400px){
        &{
          width: 55px;
          height: 55px;
          margin-top: 12px;
        }
      }
    }

  }
</style>
