<template>
  <div id="cookies">
    <transition name="fade">
  <div class="cookies p-3 p-md-4 pl-g-5" v-show="!this.accept">
    
    <p class="lh-1">На сайте используются cookie-файлы и другие аналогичные технологии. Если, прочитав это сообщение, Вы остаетесь на нашем сайте, это означает, что Вы не возражаете против использования этих технологий.</p>
    <div class="d">
        <button class="btn-ghost mr-2" @click="$parent.modals.modalcookies.show()">Узнать больше</button>
        <button class="btn-aqua" @click="setAccept()">Скрыть</button>
    </div>

  <div id="modalcookies" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-scrollable text-dark">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Политика использования файлов COOKIE</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$parent.modals.modalcookies.hide()"></button>
        </div>
        <div class="modal-body">
            <p class="font-weight-bold">Что такое cookie?</p>
            <p>Cookie — это небольшой текстовый файл, хранящий информацию о настройках интернет-браузеров. Эта технология используется практически на всех современных сайтах. Файлы cookie скачиваются браузером при первом посещении сайта. Приповторном заходе на этот сайт с того же устройства файл cookie вместе схранящейся в нем информацией либо отправляется обратно на исходный сайт(основной файл cookie), либо на сторонний сайт, которому он принадлежит(сторонний файл cookie). Таким образом, мы получаем информацию о повторном открытии сайта через тот же браузер, и в некоторых случаях может изменяться отображаемый контент. Файлы cookie могут быть полезны и пользователю, при повторном заходе делаяинтерфейс сайта более удобным и персонализированным. Если Вы используете то же устройство и тот же браузер, cookie запомнят ваши предпочтения и адаптируют отображаемый контент в соответствии с вашими интересами и потребностями.</p>
            <p class="font-weight-bold">Настройки файлов cookie на этом сайте.</p>
            <p class="font-weight-bold">Использование файлов cookie на этом сайте, не требующее согласия пользователя.</p>
            <p>Есть строго необходимые файлы cookie, так называемые технические файлы cookie, которые никак не влияют на доступность функционала сайта для пользователя. Эти файлы используются исключительно нашей компанией, поэтому считаются основными файлами cookie. Они сохраняются на вашем устройстве, только в случае, если Вы действительно посещаете наш сайт. И используются, например, для того, чтобы сайт мог отобразить версию конфигуратора, соответствующую характеристикам вашего текущего интернет-соединения.</p> 
            <p>Также такие cookie упрощают переход с протокола HTTP на HTTPS при смене страниц, тем самым обеспечивая сохранность передаваемых данных. Кроме того, они используются для хранения согласия пользователей в отношении использования файлов cookie нашим сайтом. Для использования технических файлов cookie согласие пользователя не требуется.</p>
            <p>С помощью изменения настроек на данном сайте Вы не сможете отключить установку технических файлов cookie.</p>
            <p class="font-weight-bold">Категории файлов cookie.</p>
            <p>Выделяются четыре категории файлов cookie в соответствии с их функциями и целями: технические, файлы cookie производительности, функциональные и рекламные.</p>
            <p class="font-weight-bold">Технические файлы cookie.</p>
            <p>Такие файлы важны для обеспечения навигации и использования функционала сайта. Без таких файлов cookie при возврате на страницу в рамках одного сеанса некоторые функции могут оказаться недоступными, например не сохранится история ваших действий (введенный текст, заданные значения конфигуратора).</p>
            <p class="font-weight-bold">Файлы cookie производительности.</p>
            <p>Такие файлы собирают информацию об использовании сайта — например, данные о частоте посещаемости страниц, подтверждения о получении пользователем уведомлений об ошибках и так далее. Они не сохраняют информацию, позволяющую идентифицировать конкретного пользователя. Собранная информация используется для целей статистики и носит анонимный характер. Эти файлы используются исключительно для улучшения работы сайта и повышения удобства его использования.</p>
            <p class="font-weight-bold">Функциональные файлы cookie.</p>
            <p>Такие файлы позволяют сохранять введенную информацию (имена пользователей, используемый язык и местоположение) для того, чтобы улучшить работу сайта и персонализировать его функционал и контент. Например, если используются файлы для запоминания вашего местоположения, на сайте будет отображаться информация о налогах именно в вашем регионе. Такие файлы также используются для включения запрашиваемых пользователем функций, таких как, например, воспроизведение видео. Они собирают информацию анонимно и не отслеживают ваши действия на других сайтах.</p>
            <p class="font-weight-bold">Рекламные файлы cookie.</p>
            <p>Такие файлы используются для отображения рекламных объявлений и других сообщений с учетом ваших интересов и предпочтений. Они также используются для того, чтобы ограничивать число показов одного и того же рекламного объявления одному пользователю, и для измерения эффективности рекламных кампаний. Эти файлы запоминают переходы пользователя на сайт рекламодателя / страничку с рекламным объявлением и могут передавать эту информацию сторонним организациям, например рекламным агентствам. Файлы cookie, используемые для таргетирования контента и рекламных объявлений, часто связаны с функционалом сайта, предоставляемым сторонними организациями.</p>
            <p class="font-weight-bold">Настройки файлов cookie на этом сайте.</p>
            <p>В любое время Вы можете полностью запретить использование файлов cookie, изменив настройки браузера.</p>
            <p>Если Вы измените настройки и заблокируете определенные файлы cookie, использование некоторого функционала нашего сайта станет невозможным и мы не сможем предоставить ранее заданные вами персональные настройки и параметры.</p>
       </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$parent.modals.modalcookies.hide()">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
  </div>
  </transition>
  </div>

</template>

<script>
//import cookie from 'cookie'

import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: 'Cookies',
  props: {},
  data: ()=>{
    return {
      accept: false
    }
  },
  methods:{
    setAccept(){
      this.setCookie('accept', true, {'path':'/', 'expires': this.makeDate(30)} )
      this.accept = true
    },
    getCookie: (name) => {
        var matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
                ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },

    setCookie: (name, value, options) => {
        options = options || {}

        var expires = options.expires
        if (typeof expires == "number" && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000)
            expires = options.expires = d
        }

        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString()
        }

        value = encodeURIComponent(value)
        var updatedCookie = name + "=" + value

        for (var propName in options) {
            updatedCookie += "; " + propName
            var propValue = options[propName]
            if (propValue !== true) {
                updatedCookie += "=" + propValue
            }
        }

        document.cookie = updatedCookie
    },
    makeDate: (count_of_days = 0)=>{
        var date = new Date;
        date.setDate(date.getDate() + count_of_days);
        return  date.toUTCString();
    }
  },
  created() {
     if (this.getCookie('accept') == 'true'){
       this.accept = true
     }
     //this.setCookie('accept', true, {'path':'/', 'expires': this.makeDate(7)} )
  },
  mounted() {
    this.$parent.modals.modalcookies =  new bootstrap.Modal(document.getElementById('modalcookies'), { keyboard: false })
    // this.$parent.modals.modalcookies.show()
  }
}
</script>


<style scoped lang="scss">
.cookies{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 8, 12, 0.74);
}
.btn-close {
    outline: none;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    &:hover {
      color: #000;
      text-decoration: none;
      opacity: .75;
    }
}
</style>
