<template>
  <div class="catalogitem" tabindex="-1"> <!--  @mouseenter="show_detail = true" @mouseleave="show_detail = false" -->
    <!-- <CoolLightBox :items="[`/assets/Catalog/Pools/${items[active].file}/${color}.png`]" :index="render_index" @close="render_index = null" :loop="true"></CoolLightBox> -->
    <CoolLightBox :items="photo_gallery.items" :index="photo_gallery.index" @close="photo_gallery.index = null" :loop="true"></CoolLightBox>


    <div class="row">
      <div class="col-6 pt-2 col-md-4 col-lg-3 col-xl mb-2 item mw-262px" v-for="(v, k) in items" :key="k" @click="$parent.$parent.show_modalitem({'items': items, 'active': k})"> <!-- :class="k==active && items.length > 1 ? ['active', 'cursor-pointer']:''" -->
          <p class="h5 font-weight-normal" v-text="`${v.name}`"></p>
          <div class="d-table">
            <div class="d-table-row">
              <div class="d-table-cell no-wrap small">Длина:</div>
              <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.length"></span> м.</div>
            </div>
            <div class="d-table-row">
              <div class="d-table-cell no-wrap small">Ширина:</div>
              <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.width"></span> м.</div>
            </div>
            <div class="d-table-row">
              <div class="d-table-cell no-wrap small">Глубина:</div>
              <div class="d-table-cell no-wrap w-100 small pl-2">
                <template v-if="v.depth_start == v.depth_end">
                  <span v-text="v.depth_start"></span>
                </template>
                <template v-else>
                  <span v-text="v.depth_start"></span>
                  <img class="bottom_drop mx-1" src="/assets/bottom_drop.png" alt=" - ">
                  <span v-text="v.depth_end"></span>
                </template>
              </div>
            </div>
            <div class="d-table-row">
              <div class="d-table-cell no-wrap small">Объем:</div>
              <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.volume"></span> м.<sup>3</sup></div>
            </div>
          </div>
        <p class="no-wrap">
          <span v-text="$parent.$parent.number_format(v.price)"></span> р.
        </p>
        <img alt="" class="w-100" :src="`assets/Catalog/Pools/${v.file}/list_render_thm.png`">
      </div>

      <!--
      <transition name="show" mode="out-in">
        <div class="col-lg-12 py-4 pt-xl-5" v-if="items" v-show="show_detail">
          <div class="row">
            <div class="col-12 col-md-6 order-1 order-md-0 d-flex flex-column justify-content-around">
              <div class="my-3">
                <span v-for="(cn, ci) in all_colors" :key="ci">
                  <img class="all_colors popover-dismiss" :src="`/assets/Catalog/Colors/${ci}.png`" alt="" :title="cn" v-if="items[active][ci] == 1" @click="color = ci" :class="{active: ci == color}">
                </span>                
              </div>
              <div class="my-3 text-center">
                <template v-if="items[0].cnt_imgs > 0">
                  <button class="btn-aqua aqua-shine" @click="open_photo_gallery()">Открыть фотогалерею <br class="d-block d-md-none"> (<span v-text="items[0].cnt_imgs"></span> фото) </button>
                </template>
              </div>
              <div class="my-3 text-center">
                <button class="btn-yellow yellow-shine" @click="$parent.$parent.show_modalorder(`Заказать бассейн ${items[active].name}, цвет ${all_colors[color]}`)">Заказать в 1 клик</button>  
              </div>
            </div>
            <div class="col-12 col-md-6 order-0 order-md-1">
              <div class="render_wr">
                <img class="w-100 cursor-pointer" :src="`/assets/Catalog/Pools/${items[active].file}/${color}.png`" alt="" @click="render_index = 0">
              </div>   
              <div class="color_name_wr mt-2">Выбранный цвет: 
                  <span v-text="all_colors[color]"></span>
              </div>         
            </div>
          </div>
        </div>      
      </transition>
      -->

    </div>
  </div>
</template>

<script>
 import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'CatalogItem',
  components: {
    CoolLightBox
  },
  props: {
    items: Array
  },
  data: function(){
    return {
      // show_detail:false,
      // all_colors: {'3DW':'3D White', 'BG': 'Black Galaxy', 'BI': 'Blue Iridium', 'BS': 'Blue Stone', 'C': 'Coral', 'E': 'Emerland', 'G': 'Granite', 'NC': 'Без цвета', 'NS': 'Night Shine', 'RS': 'Russian Shine', 'SB': 'Sandy Beach', 'SF': 'Sea Foam', 'SFL': 'Sea Foarm Light', 'SS': 'Sky Shine', 'TB': 'Tiffany Blue'},
      title: null,
      active: 0,
      // color: 'BI',
      render_index: null,
      photo_gallery: {
        items:[],
        index:null
      }
    }
  },
  mounted(){
    // this.$parent.modals.modalitem =  new bootstrap.Modal(document.getElementById('modalitem'), { keyboard: false })
  },
  watch:{
    obj: function(n){
      this.active = 0
      // this.color = 'BI'
    }
  },
  methods:{
    set_active_model(index){
      this.active = index // переключаем бассейн
      // если был выбран цвет, которого нет у другой модели - включаем блю иридиум
      if (this.items[this.active][this.color] == 0){
        this.color = 'BI'
      }
    },

    open_photo_gallery(){
      

      this.photo_gallery.items = []
      for (let i = 1; i <= this.items[0].cnt_imgs; i++){
        this.photo_gallery.items.push(`/assets/Catalog/Photogallery/${this.items[0].series_name}/${i}.jpg`)
      }
      this.photo_gallery.index = 0
    }
    /*
    open_gallery: function(file_name, cnt_imgs){
      this.gallery.items = []
      for (let i = 1; i <= cnt_imgs; i++){
        this.gallery.items.push(`/assets/pools/${file_name}/${i}.jpg`)
      }
      this.gallery.items.push(`/assets/pools/${file_name}.png`)
      this.gallery.index = 0
    },
    */
  }
}
</script>


<style scoped lang="scss">
.modal-content{
  background-color: #1B3036;
  margin-bottom: 20px;
  max-width: 100%;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  overflow: visible;
  box-shadow: 0 0 20px 0 #1B3036;
  padding: 10px 16px;
  @media(min-width:992px){
    &{
      padding: 60px 96px;
    }
  }
}

input{
  transition: border-color 0.3s linear;
  box-sizing: border-box;
  width: 100%;
  display: block;
  color: #3b5058;
  margin: 0;
  border: 1px solid #bac3cb;
  border-radius: 100px;
  outline: 0;
  height: 52px;
  line-height: 52px;
  padding: 0 30px;
  background: #fff;
  &.has-error{
    border: 2px solid #fb0000;
  }
  &::placeholder {
    transition: color .3s linear;
    color: #6c919f;
  }
}

.modal-header, .modal-footer{
  border:none;
}

.privacy{
  font-size: 0.75rem;
  color: #69838a;
  line-height: 1;
}

.btn-close {
    outline: none;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #fff;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    &:hover {
      color: #ddd;
      text-decoration: none;
      opacity: .75;
    }
}


.item{
  cursor:pointer;
  transition: all ease .15s;
  &:hover{
    border-radius: 5px;
    background-color: #59b6d31c;
  }
  &.active{
    cursor:default;
    border: 1px solid #76d9f3;
    //padding: .5rem;
    border-radius: 5px;
    background-color: rgba(81, 169, 199, 0.2);
  }
}

.all_colors{
  width:58px;
  height:58px;
  padding:0.25rem;
  cursor: pointer;
  transition: all ease .3s;
  &.active{
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 8px #51a9c7);
  }
}

.mw-262px{
  min-width: 262px;
  max-width: 262px;
}

.bottom_drop{
  width: 30px;
  height: auto;
}
</style>
