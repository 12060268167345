import { render, staticRenderFns } from "./FixedCallback.vue?vue&type=template&id=28dfcbf0&scoped=true&"
import script from "./FixedCallback.vue?vue&type=script&lang=js&"
export * from "./FixedCallback.vue?vue&type=script&lang=js&"
import style0 from "./FixedCallback.vue?vue&type=style&index=0&id=28dfcbf0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28dfcbf0",
  null
  
)

export default component.exports