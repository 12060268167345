<template>
  <div id="app" :class="modals.modalitem._isShown && !modals.modalitem._isTransitioning ? 'on_modal_opened' : ''">
    <transition :duration="500" name="fade">
    <Menu v-show="opened_menu" :phone="phone" :email="email" :opened_menu="opened_menu" @open_menu="opened_menu = $event"></Menu>
    </transition>
    <Header :phone="phone" :email="email" :opened_menu="opened_menu" @open_menu="opened_menu = $event"></Header>
    <OtherCatalog></OtherCatalog>
    <Catalog @set_catalog="catalog = $event"></Catalog>
    <ConstructionSteps></ConstructionSteps>
    <Quiz :order="order" :catalog="catalog" :choosed_pool="choosed_pool"></Quiz>
    <Wood branch="left"></Wood>
    <Advantages></Advantages>
    <Photogallery></Photogallery>
    <Certs></Certs>
    <Contacts :phone="phone" :email="email" :order="order"></Contacts>
    <Footer></Footer>
    <Wood branch="right"></Wood>
    <ModalPrivacy></ModalPrivacy>
    <ModalOrder :modalorder="modalorder" :order="order"></ModalOrder>
    <ModalItem :obj="modalitem.obj" @choose_pool="choose_pool($event)"></ModalItem>
    <FixedCallback v-show="!order.sended" :order="order"></FixedCallback>
    <Cookies></Cookies>
  </div>
</template>

<script>


import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Catalog from './components/Catalog.vue'
import OtherCatalog from './components/OtherCatalog.vue'
import ConstructionSteps from './components/ConstructionSteps.vue'
import Quiz from './components/Quiz.vue'
import Wood from './components/Wood.vue'
import Advantages from './components/Advantages.vue'
import Photogallery from './components/Photogallery.vue'
import Certs from './components/Certs.vue'
import Contacts from './components/Contacts.vue'
import Footer from './components/Footer.vue'
import ModalPrivacy from './components/ModalPrivacy.vue'
import ModalOrder from './components/ModalOrder.vue'
import ModalItem from './components/ModalItem.vue'
import FixedCallback from './components/FixedCallback.vue'
import Cookies from './components/Cookies.vue'


import 'bootstrap/dist/css/bootstrap.min.css'
import '/public/assets/font/stylesheet.css'


import axios from 'axios'

/*
if (process.env.NODE_ENV == "development"){
    axios.defaults.baseURL = 'https://localhost/composite/';
}
*/


export default {
  name: 'App',
  components: {
    Header, Menu, Catalog, OtherCatalog, ConstructionSteps,
    Quiz, Wood, Advantages, Photogallery,
    Certs, Contacts, Footer, ModalPrivacy,
    ModalOrder, ModalItem, FixedCallback, Cookies
  },
  data() {
    return {
        opened_menu: false,
        phone: `8 (906) 463-97-98`,
        email: `sales@composite-pool.ru`,
        catalog: [],
        modalorder: {
          title: null,
        },
        modalitem: {
          obj: {},
        },
        order:{
          sended: false,
          fields_has_errors: []
        },
        modals: {
          modalprivacy: {},
          modalorder: {},
          modalitem: {},
          modalcookies: {},
        },
        choosed_pool:{ //Выбранный бассейн из модуля каталога в модуль квиза.
          name: null,
          size: null,
        }
      };
},
  watch:{
    "order.sended": function(n){
      if (n == true){
        if(typeof(window.ym) == "function"){
            window.ym(71326590,'reachGoal','callback')        
        }
      }
    }
  },
  methods:{
    choose_pool(pool){
      this.choosed_pool.name = pool.series_name
      this.choosed_pool.size = pool.length + ' • ' + pool.width + ' м.'
    },
    send_order(info){
      //const devUrl = `http://localhost/composite`
      //console.log (info)
        const fd = new FormData()
        fd.append('data', JSON.stringify(info))

      //axios.post(devUrl+'/send/', fd).then((r)=>{
      axios.post('/send/', fd).then((r)=>{

        this.order.fields_has_errors = r.data.fields_has_errors;
        if (r.data.status == `success`){
          this.order.sended = true
        }
      })
    },
    show_modalorder(title){
      this.modalorder.title = title
      if (this.modals.modalitem._isShown && !title){
        this.modalorder.title = `Заказать бассейн ${this.modalitem.obj.items[this.modalitem.obj.active].name}`
      }
      this.modals.modalitem.hide()
      this.modals.modalorder.show()
    },
    show_modalitem(obj){
      this.modalitem.obj = obj
      this.modals.modalitem.show()
    },
    scroll(el){
      const scrollDiv = document.getElementById(el).offsetTop
      setTimeout(()=>{
        window.scrollTo({ top: scrollDiv, behavior: 'smooth'})
      }, 150)
    },
    number_format(number, round = 0){
      if (round == 0){
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return number.toFixed(round).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    },
  },
  created() {
  //   console.log (cookie);
  },
  
}
</script>

<style lang="scss">
html, body{
  background-color: #08242f;
}
#app {
  font-family: 'Muller';
  color: #fff;
  background-color: #08242f;
  .text-link-color{
    color: #23C6DB;
  }
  .p-relative{
    position: relative;
  }
  .no-wrap{
    white-space: nowrap;
  }

  .text-yellow{
    color:#E8FC00;
  }

  .btn-ghost{
      background-color: transparent;
      transition: border-color 0.1s linear,background-color 0.1s linear,color 0.1s linear;
      color: #fff;
      box-sizing: border-box;
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      text-decoration: none;
      line-height: normal;
      border: 0;
      outline: 0;
      padding: 16px 30px 14px;
      cursor: pointer;
      overflow: hidden;
      text-shadow: none;
      box-shadow: none;
      border-radius: 200px;
      border: 1px solid #fff;
      &:hover{
        border-color: #23C6DB;
        color: #23C6DB;
      }
  }

  .btn-aqua{
      background-color: #23C6DB;
      border-color: #23C6DB;
      color: #fff;
      box-sizing: border-box;
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      text-decoration: none;
      line-height: normal;
      border: 0;
      outline: 0;
      padding: 16px 30px 14px;
      cursor: pointer;
      overflow: hidden;
      text-shadow: none;
      box-shadow: none;
      border-radius: 200px;
  }

  .btn-grey{
      background-color: #cacaca;
      border-color: #23C6DB;
      color: #fff;
      box-sizing: border-box;
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      text-decoration: none;
      line-height: normal;
      border: 0;
      outline: 0;
      padding: 16px 30px 14px;
      cursor: pointer;
      overflow: hidden;
      text-shadow: none;
      box-shadow: none;
      border-radius: 200px;
  }

  .grey-shine{
    box-shadow: 0 15px 30px rgba(174,174,174,.25);
    transition: box-shadow 0.1s linear;
    &:hover{
      box-shadow: 0 0 0 rgba(232,252,0,0);
    }
  }

  .aqua-shine{
    box-shadow: 0 15px 30px rgba(35,198,219,.25);
    transition: box-shadow 0.1s linear;
    &:hover{
      box-shadow: 0 0 0 rgba(232,252,0,0);
    }
  }

  .btn-yellow{
      background-color: #E8FC00;
      color: #000;
      box-sizing: border-box;
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      text-decoration: none;
      line-height: normal;
      border: 0;
      outline: 0;
      padding: 16px 30px 14px;
      cursor: pointer;
      overflow: hidden;
      text-shadow: none;
      box-shadow: none;
      border-radius: 200px;
  }
  
  .yellow-shine{
    box-shadow: 0 15px 30px rgba(232,252,0,.3);
    transition: box-shadow 0.1s linear;
    &:hover{
      box-shadow: 0 0 0 rgba(232,252,0,0);
    }
  }
  
}

.cursor-pointer{
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active { transition: opacity .5s; } .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ { opacity: 0; }
.show-enter-active, .show-leave-active { transition: all .15s; transform: translateX(0);}.show-enter, .show-leave-to { opacity: 0; transform: translateX(-400px);}
</style>
