<template>
  <div id="certs" class="py-5">
    <CoolLightBox :items="gallery.items" :index="gallery.index" @close="gallery.index = null" :loop="true"></CoolLightBox>
    <div class="container overflow-hidden p-3 p-lg-5">
      <p class="h1 text-left border-bottom-yellow">Сертификаты</p>
      <div class="row mt-5 g-3">
        <div class="col-4 item" v-for="(v, k) in gallery.items" :key="k" @click="gallery.index = k">
          <img :src="v" class="w-100" alt="">
          <svg class="svg_zoom" viewBox="0 0 512.005 512.005">
            <use xlink:href="#zoom"></use>
            <symbol id="zoom" viewBox="0 0 512.005 512.005">
                <path d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587zM202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"></path>
            </symbol>
            </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'

export default {
  name: 'Certs',
  components: {
    CoolLightBox
  },
  props: { },
  data: function(){
    return {
      gallery: {
        index: null,
        items: ['/assets/cert/c1.jpg', '/assets/cert/c2.jpg',  '/assets/cert/c3.jpg']
      }
    }
  }
}
</script>


<style scoped lang="scss">
  #certs{

    .container{
      z-index: 4;
      box-shadow: 0 0 40px 0 rgba(0,0,0,.6);
      border-radius: 30px;
      overflow: hidden;
      background-color: transparent;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: url('/assets/water.jpg');
        background-size: cover;
        opacity: .4;
      }
    }

    .border-bottom-yellow{
      position: relative;
      &::after{
        content: '';
        position: absolute;
        left: -5%;
        bottom: -2rem;
        height: 1px;
        background-color: #E8FC00;
        width: 30%;
        margin: auto;
        min-width: 400px;
        @media(max-width: 572px){
          &{
            min-width: 350px;
          }
        }
      }
    }

    .svg_zoom{
      position: absolute;
      fill: #E8FC00;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 30%;
      height: 30%;
      transition: opacity .2s linear,transform .2s linear;
      opacity: 0;
      transform: scale(0);
      cursor: pointer;
    }

    .item{
      position: relative;
      cursor: pointer;

      &::before {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        opacity: 0;
        background: #08242F;
        transition: opacity .1s linear;
      }

      .svg_zoom{
        width: 84px;
        height: 84px;
        fill: #E8FC00;
      }
      &:hover{
        .svg_zoom{
          opacity: 1;
          transform: scale(1);
        }
        &::before {
            opacity: 0.4;
        }
      }
    }
  }
</style>
