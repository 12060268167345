<template>
  <div id="quiz">
    <div class="container shadow-lg p-0">
      <div class="top pb-4  p-4 p-lg-5">
        <div class="row">
          <div class="col-12 col-lg-6">
            <p class="h1">Ответьте на <span class="text-yellow">7 вопросов</span></p>
            <p class="h4 font-weight-light">Вы получите <span class="text-yellow">точный расчет</span> стоимости композитного бассейна с установкой</p>
          </div>
          <div class="col-12 col-lg-6">
            <div class="steps overflow-hidden">
              <div class="progress" :style="{width:current_step*100/(steps-1)+'%'}"></div>
              <ul class="list-unstyled m-0 p-0 h-100 d-flex flex-row justify-content-between">
                <li class="step" v-for="step in steps" :key="step" v-text="step" :class="{active: current_step  >= step }"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 p-lg-5 pt-4 variants text-dark">

        <transition name="fade" mode="out-in">
        
        <div class="variant p-2" v-if="current_step == 1" key="1">
          <p class="h2">Какой бассейн планируете строить?</p>

          <div class="row" v-if="catalog.length">
            <div class="col-6 col-md-4 col-xl-4 my-3 item py-2" 
              v-for="(series, series_index) in catalog" 
              :key="series_index" 
              @click.stop="result.pool=series.series_name;" 
              @dblclick="result.pool=series.series_name; next_step() "
              :class="{active:result.pool==series.series_name}"
            >
                  <img class="w-100" :src="`assets/Catalog/Pools/${series.items[0].file}/list_render_thm.png`" alt="">

                  <p class="mb-0"><span class="option inline" :class="{active:result.pool==series.series_name}"></span> 
                    <span class="mr-3 mb-2" v-text="series.series_name"></span>
                    <select class="form-select d-inline w-auto mt-2 mt-xl-0" v-model="result.size" v-if="result.pool==series.series_name">
                      <option value="null">- размер -</option>
                      <option v-for="(v, k) in series.items" :key="k" :value="v.length + ' • ' + v.width + ' м.'" v-text="v.length + ' • ' + v.width + ' м.'"></option>
                    </select>
                  </p>

            </div>
          </div>

          <div class="btns mt-3">
            <button class="btn-aqua aqua-shine px-4" @click="next_step_required('pool')">Далее</button>
            <p class="text-danger d-inline-block mt-2 ml-3" v-if="show_error">Выберите один из пунктов</p>
          </div>
        </div>




        <div class="variant p-2" v-if="current_step == 2" key="2">
          <p class="h4">Где хотите строить?</p>
          <div class="row g-0">

            <div class="col-6 col-md-4 item pb-2" @click="result.position='in'; next_step();" :class="{active:result.position=='in'}">
              <div class="p-2">
                <img src="/assets/calc/2/in.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.position=='in'}"></span> Внутри здания</p>
            </div>
            <div class="col-6 col-md-4 item pb-2" @click="result.position='out'; next_step();" :class="{active:result.position=='out'}">
              <div class="p-2">
                <img src="/assets/calc/2/out.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.position=='out'}"></span> На улице</p>
            </div>

          </div>
          <div class="btns mt-3">
            <button class="btn-grey grey-shine px-4" @click="history_prev_step()">Назад</button>
            <button class="btn-aqua aqua-shine px-4 ml-3" @click="next_step_required('position')">Далее</button>
            <p class="text-danger d-inline-block mt-2 ml-3" v-if="show_error">Выберите один из пунктов</p>
          </div>
        </div>


        <div class="variant p-2" v-if="current_step == 3" key="3">
          <p class="h4">Какой цвет препочитаете?</p>
          <div class="row g-0">
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.color='blue_iridium'; next_step();" :class="{active:result.color=='blue_iridium'}">
              <div class="p-2">
                <img src="/assets/calc/3/blue_iridium.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.color=='blue_iridium'}"></span> Blue iridium</p>
            </div>
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.color='blue_stone'; next_step();" :class="{active:result.color=='blue_stone'}">
              <div class="p-2">
                <img src="/assets/calc/3/blue_stone.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.color=='blue_stone'}"></span> Blue stone</p>
            </div>
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.color='coral'; next_step();" :class="{active:result.color=='coral'}">
              <div class="p-2">
                <img src="/assets/calc/3/coral.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.color=='coral'}"></span> Coral</p>
            </div>
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.color='other'; next_step();" :class="{active:result.color=='blue_otherstone'}">
              <div class="p-2">
                <img src="/assets/calc/3/other.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.color=='other'}"></span> Другой</p>
            </div>
          </div>
          <div class="btns mt-3">
            <button class="btn-grey grey-shine px-4" @click="history_prev_step()">Назад</button>
            <button class="btn-aqua aqua-shine px-4 ml-3" @click="next_step_required('color')">Далее</button>
            <p class="text-danger d-inline-block mt-2 ml-3" v-if="show_error">Выберите один из пунктов</p>
          </div>
        </div>


        <div class="variant p-2" v-if="current_step == 4" key="4">
          <p class="h4">Какое накрытие для бассейна?</p>
          <div class="row g-0">
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.cover='pavilion'; next_step();" :class="{active:result.cover=='pavilion'}">
              <div class="p-2">
                <img src="/assets/calc/4/pavilion.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.cover=='pavilion'}"></span> Павильон</p>
            </div>
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.cover='roller-shutter'; next_step();" :class="{active:result.cover=='roller-shutter'}">
              <div class="p-2">
                <img src="/assets/calc/4/roller-shutter.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.cover=='roller-shutter'}"></span> Роллетное покрывало</p>
            </div>
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.cover='solar-film'; next_step();" :class="{active:result.cover=='solar-film'}">
              <div class="p-2">
                <img src="/assets/calc/4/solar-film.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.cover=='solar-film'}"></span> Солярная пленка</p>
            </div>
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.cover='none'; next_step();" :class="{active:result.cover=='none'}">
              <div class="p-2">
                <img src="/assets/calc/4/none.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline" :class="{active:result.cover=='none'}"></span> Без накрытия</p>
            </div>
          </div>
          <div class="btns mt-3">
            <button class="btn-grey grey-shine px-4" @click="history_prev_step()">Назад</button>
            <button class="btn-aqua aqua-shine px-4 ml-3" @click="next_step_required('cover')">Далее</button>
            <p class="text-danger d-inline-block mt-2 ml-3" v-if="show_error">Выберите один из пунктов</p>
          </div>
        </div>


        <div class="variant p-2" v-if="current_step == 5" key="5">
          <p class="h4 mb-0">Выберите дополнительное оборудование для бассейна</p>
          <p class="small">Можно выбрать несколько вариантов</p>
          <div class="row g-0">

            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.oeq.lighting=!result.oeq.lighting" @dblclick="next_step();" :class="{active:result.oeq.lighting}">
              <div class="p-2">
                <img src="/assets/calc/5/lighting.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline rectangle" :class="{active:result.oeq.lighting}"></span> Освещение</p>
            </div>

            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.oeq.heating=!result.oeq.heating" @dblclick="next_step();" :class="{active:result.oeq.heating}">
              <div class="p-2">
                <img src="/assets/calc/5/water-heating.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline rectangle" :class="{active:result.oeq.heating}"></span> Обогрев</p>
            </div>

            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.oeq.dosing=!result.oeq.dosing" @dblclick="next_step();" :class="{active:result.oeq.dosing}">
              <div class="p-2">
                <img src="/assets/calc/5/dosing.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline rectangle" :class="{active:result.oeq.dosing}"></span> Дозирование хим. реагентов</p>
            </div>

            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.oeq.uv=!result.oeq.uv" @dblclick="next_step();" :class="{active:result.oeq.uv}">
              <div class="p-2">
                <img src="/assets/calc/5/uv.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline rectangle" :class="{active:result.oeq.uv}"></span> Ультрафиотетовая установка</p>
            </div>

          </div>
          <div class="btns mt-3">
            <button class="btn-grey grey-shine px-4" @click="history_prev_step()">Назад</button>
            <button class="btn-aqua aqua-shine px-4 ml-3" @click="next_step_required('oeq')">Далее</button>
            <p class="text-danger d-inline-block mt-2 ml-3" v-if="show_error">Выберите нужные опции</p>
          </div>
        </div>



        <div class="variant p-2" v-if="current_step == 6" key="6">
          <p class="h4 mb-0">Выберите аттракционы для бассейна</p>
          <p class="small">Можно выбрать несколько вариантов</p>
          <div class="row g-0">
            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.attr.hm4=!result.attr.hm4" @dblclick="next_step();" :class="{active:result.attr.hm4}">
              <div class="p-2">
                <img src="/assets/calc/6/hm4.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline rectangle" :class="{active:result.attr.hm4}"></span> Гидромассаж 4 форсунки</p>
            </div>

            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.attr.hm8=!result.attr.hm8" @dblclick="next_step();" :class="{active:result.attr.hm8}">
              <div class="p-2">
                <img src="/assets/calc/6/hm8.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline rectangle" :class="{active:result.attr.hm8}"></span> Гидромассаж 8 форсунок</p>
            </div>

            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.attr.stream2=!result.attr.stream2" @dblclick="next_step();" :class="{active:result.attr.stream2}">
              <div class="p-2">
                <img src="/assets/calc/6/stream2.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline rectangle" :class="{active:result.attr.stream2}"></span> Противоток 2 сопла</p>
            </div>

            <div class="col-6 col-md-4 col-lg-3 item pb-2" @click="result.attr.stream4=!result.attr.stream4" @dblclick="next_step();" :class="{active:result.attr.stream4}">
              <div class="p-2">
                <img src="/assets/calc/6/stream4.jpg" alt="" class="img-fluid">
              </div>
              <p class="mb-0"><span class="option inline rectangle" :class="{active:result.attr.stream4}"></span> Противоток 4 сопла</p>
            </div>
          </div>
          <div class="btns mt-3">
            <button class="btn-grey grey-shine px-4" @click="history_prev_step()">Назад</button>
            <button class="btn-aqua aqua-shine px-4 ml-3" @click="next_step_required('attr')">Далее</button>
            <p class="text-danger d-inline-block mt-2 ml-3" v-if="show_error">Выберите нужные опции</p>
          </div>
        </div>


        <div class="variant p-2" v-if="current_step == 7" key="7">
          <template v-if="!order.sended">
            <p class="h4 mb-0">Спасибо за уделённое время! Тест почти пройден!</p>
            <p class="small mb-0">Точный расчет почти готов куда вам его отправить?</p>
            <p class="small">Для получения укажите e-mail или контактный телефон.</p>
            <div class="row g-0">
              <div class="col-12 col-md-6 col-lg-5">
    
              <div class="mb-3">
                <label for="city" class="form-label">В какой город доставка?</label>
                <input type="text" class="form-control" id="city" v-model="result.city"  :class="{'has-error': order.fields_has_errors.indexOf('city') >= 0}">
              </div>

              <div class="mb-3">
                <label for="email" class="form-label">Ваш E-mail</label>
                <input type="email" class="form-control" id="email" v-model="result.email"  :class="{'has-error': order.fields_has_errors.indexOf('email') >= 0}">
              </div>

              <div class="mb-3">
                <label for="phone" class="form-label">Ваш телефон</label>
                <input type="text" class="form-control" id="phone" v-mask="`+7 (999) 999-99-99`" v-model="result.phone"  :class="{'has-error': order.fields_has_errors.indexOf('phone') >= 0}">
              </div>

              <button class="btn-aqua aqua-shine" @click="send()">Отправить</button>
              <p class="small mb-0 mt-3 lh-1">Нажимая "Отправить", Вы даете свое согласие на обработку персональных данных в соответствии с Федеральным законом от 27.07.2006 №152-Ф3 "О персональных данных"</p>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="h4 mb-0">Заявка успешно отправлена</p>
            <p class="mb-0 text-left">Ожидайте звонка. Наш менеджер перезвонит Вам в ближайшее время</p>
          </template>
        </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Quiz',
  props: {
    order: Object,
    catalog: Array,
    choosed_pool: Object
  },
  data: () => {
    return {
      steps:7,
      current_step: 1,
      show_error: false,
      result:{
        phone:null,
        city:null,
        email:null,
        title:null,
        pool:null,
        size:null,
        position:null,
        color:null,
        cover:null,
        flag_changed: false,
        oeq:{
          lighting:false,
          heating:false,
          stairs:false,
          pavilion:false,
          // disinfection:false,
          dosing:false,
          uv:false
        },
        attr:{
          hm4:false,
          hm8:false,
          stream2:false,
          stream4:false,
        }
      }
    }
  },
  methods:{
    next_step(){
      this.current_step++
      history.pushState({}, "popstate", "")
    },
    history_prev_step(){
      if (this.current_step > 1){
        this.current_step--
      }
    },
    send(){
        this.$set(this.result, `title`, `Прохождение опроса`)
        this.$parent.send_order( Object.assign({}, this.result) )
    },
    next_step_required(type){
      if (this.result[type]){
        this.next_step();
      } else {
        this.show_error = true
      }
    },
    update_from_catalog(){

        /*
        this.flag_changed = false
        this.current_step = 1
        this.result.size = null
        setTimeout(()=>{this.result.pool = this.choosed_pool.name;}, 1000)
        setTimeout(()=>{this.result.size = this.choosed_pool.size}, 1800)
        setTimeout(()=>{this.current_step = 2}, 2500) 
        */

        this.flag_changed = false
        this.current_step = 2
        this.result.pool = this.choosed_pool.name
        this.result.size = this.choosed_pool.size
    }
  },
  watch:{
      current_step(){
        this.show_error = false
      },
      "result.pool": function(new_val){
        if (new_val != this.choosed_pool.name || this.flag_changed){
          this.result.size = null
        }
        this.flag_changed = true
      },
      "choosed_pool.name": function(){      
        this.update_from_catalog()
      },
      "choosed_pool.size": function(){  
        this.update_from_catalog()        
      }
  },
  created: function(){
    window.addEventListener('popstate', (e)=>{
      this.history_prev_step()
    }, false);
  }
}
</script>

<style scoped lang="scss">
  #quiz{
    position: relative;

    padding-top: 3rem;
    padding-bottom: 3rem;

    @media(min-width:768px){
      &{
        padding-bottom: 5rem;
      }
    }
    @media(min-width:1200px){
      &{
        padding-bottom: 10rem;
      }
    }


    &::before{
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: linear-gradient(to bottom,rgba(55,87,91,0) 0%,#37575b 100%);
    }

    .container{
      position: relative;
      background-color: #1B3036;
      border-radius: 45px;
      overflow: hidden;
    }


    .steps{
      position: relative;
      transition: all ease .3s;

      .progress{
        position: absolute;
        top: calc(50% - 1px);
        right: 0;
        left: 0;
        content: '';
        border-top: 1px solid #E8FC00;
        transition: all ease 0.6s;
        height: 0;
      } 

      ul{
        position: relative;
      }

      .step{
        cursor: default;
        border: 1px solid #6799A6;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 24px;
        font-weight: normal;
        color: #6799A6;
        background-color: #1B3036;
        transition: all ease .3s;

        &.active{
          border: 1px solid #E8FC00;
          color: #E8FC00;
        }
      }
    }


    .variants{
      background-color: #F0F8FF;
      .variant{
        .item{
          cursor: pointer;
          position: relative;
          border: 1px solid rgba(35,198,219,0);
          &.active{
            background-color: #f7fbff;
            border: 1px solid rgba(35,198,219,.2);
          }  
          &:hover{
            background-color: #f3f9ff;
            border: 1px solid rgba(35,198,219,.1);
          }                 
        }

      }

      .option{
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        border: 1px solid #23C6DB;
        width: 22px;
        height: 22px;
        box-sizing: border-box;
        &.inline{
          position: relative;
          display: inline-block;
          top: 6px;
          left: 6px;
          margin-right: 11px;
        }
        &::before{
          border-radius: 50%;
          position: absolute;
          top: 4px;
          right: 4px;
          bottom: 4px;
          left: 4px;
          content: '';
          background-color: #23C6DB;
          opacity: 0;
          transition: opacity .1s linear;
        }

        &.active{
          &::before{
            opacity: 1;
          }
        }

        &.rectangle{
          border-radius: 0!important;
          &.active{
            &::before{
              border-radius: 0!important;
            }
          }
        }

      }
    }

    .has-error{
      border: 2px solid #fb0000;
    }

    .quiz_pool_thm{
      width: 3rem;
      img{
        height: 1rem;
      }
    }
    .pool_item{
      cursor: pointer;
      transition: all linear .1s;
      &.active{
        font-weight: 700;
        background-color: #fff;
      }
       &:hover{
        background-color: #fff;
        color: #000;
        box-shadow: 0 0 15px #eee;
      }
    }

  }
</style>
