<template>
  <div id="modalorder" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-header">
          <div class="text-left">
          <h2 class="modal-title lh-1 mb-3" v-text="modalorder.title"></h2>
            <p class="small" v-if="!order.sended">Наш менеджер перезвонит Вам в ближайшее время</p>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$parent.modals.modalorder.hide()"></button>
        </div>

        <div class="modal-body">
          <template v-if="!order.sended">
            <input class="mb-4" placeholder="Ваш город" v-model="info.city" :class="{'has-error': order.fields_has_errors.indexOf('city') >= 0}">
            <input class="mb-0" placeholder="Ваш телефон" v-model="info.phone" v-mask="`+7 (999) 999-99-99`" :class="{'has-error': order.fields_has_errors.indexOf('phone') >= 0}">
          </template>
          <template v-else>
            <p class="mb-0 text-left">Заявка успешно отправлена. Ожидайте звонка.</p>
          </template>
        </div>

        <div class="modal-footer justify-content-center">
          <template v-if="!order.sended">
            <button type="button" class="btn-yellow yellow-shine" @click="send()">Отправить</button>
            <p class="mt-3 privacy">Нажимая "Отправить", Вы даете свое согласие на обработку персональных данных в соответствии с Федеральным законом от 27.07.2006 №152-Ф3 "О персональных данных"</p>
          </template>
          <template v-else>
            <button type="button" class="btn-ghost tmt-3" @click="$parent.modals.modalorder.hide()">Закрыть окно</button>
          </template>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: 'ModalOrder',
  props: {
    modalorder: Object,
    order: Object
  },
  data: function(){
    return {
      info: {
        obj:{ },
        phone: null,
        city: null
      }
    }
  },
  mounted(){
    this.$parent.modals.modalorder =  new bootstrap.Modal(document.getElementById('modalorder'), { keyboard: false })
  },
  methods:{
    send(){
      this.$set(this.info.obj, `title`, this.modalorder.title)
      this.$parent.send_order( Object.assign({}, this.info) )
      // if status == success $parent.modals.modalprivacy.hide()
    }
  }
}
</script>


<style scoped lang="scss">
.modal-content{
  background-color: #1B3036;
  margin-bottom: 20px;
  max-width: 100%;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  overflow: visible;
  box-shadow: 0 0 20px 0 #1B3036;
  padding: 10px 16px;
  @media(min-width:992px){
    &{
      padding: 60px 96px;
    }
  }
}

input{
  transition: border-color 0.3s linear;
  box-sizing: border-box;
  width: 100%;
  display: block;
  color: #3b5058;
  margin: 0;
  border: 1px solid #bac3cb;
  border-radius: 100px;
  outline: 0;
  height: 52px;
  line-height: 52px;
  padding: 0 30px;
  background: #fff;
  &.has-error{
    border: 2px solid #fb0000;
  }
  &::placeholder {
    transition: color .3s linear;
    color: #6c919f;
  }
}

.modal-header, .modal-footer{
  border:none;
}

.privacy{
  font-size: 0.75rem;
  color: #69838a;
  line-height: 1;
}

.btn-close {
    outline: none;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #fff;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    &:hover {
      color: #ddd;
      text-decoration: none;
      opacity: .75;
    }
}
</style>
