<template>
  <div id="constructionsteps" class="py-5">
    <div class="container p-4 p-lg-5">
      <p class="h1">7 шагов монтажа бассейна</p>
      <div class="d-flex flex-row">
        <div class="steps">
          <ul class="list-unstyled m-0 p-0 h-100 d-flex flex-column justify-content-around">
            <li class="step my-3 my-xl-3" v-for="(v, k) in steps" :key="k" v-text="k+1" :class="{active:(k == current_step)}" @click="current_step = k"></li>
          </ul>
        </div>
        <div class="descr mt-4 ml-4 ml-xl-5" @click="next_step()">
          <p class="h2 font-weight-normal">{{current_step+1}} шаг</p>
          <div class="row">
            <div class="col-12 col-md-5">
              <p class="text" v-for="(v, k) in steps[current_step]" :key="k" v-text="v"></p>
            </div>
            <div class="col-12 col-md-7">
              <img class="img-fluid" :src="`/assets/ConstructionSteps/step-${current_step+1}.png`" alt="">
            </div>
          </div>
          <button class="btn-yellow yellow-shine" @click.stop="$parent.show_modalorder(`Получить консультацию`)">Консультация с нами</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConstructionSteps',
  props: {},
  data: () => {
    return {
      steps:[
        ['Перед установкой чаши бассейна необходимо определить место под установку, и подготовить котлован согласно заданным размерам.'],
        ['На дне котлована отливается армированная бетонная плита. Толщина 150 - 200 мм.'],
        ['Производится установка нужного уровня, и подушка проливается водой.', 'На готовую подушку ложится пенопласт, толщиной 5 см и плотностью не менее 25.'],
        ['Устанавливаем чашу бассейна в яму.'],
        ['Выставляем опалубку из пенопласта по периметру чаши бассейна.', 'Производим врезку закладных элементов и подключения оборудования.'],
        ['Наполняем бассейн водой на 30см за один цикл засыпки.', 'Одновременно с наполнением, засыпаем промежуток между опалубкой и бассейном сухой смесью из цемента и песка, затем проливаем водой и трамбуем.', 'Расстояние между опалубкой и краями ямы засыпается землёй или песком.'],
        ['Бассейн готов к использованию']
      ],
      current_step: 0
    }
  },
  methods:{
    next_step(){
      if (this.current_step < (this.steps.length - 1)){
        this.current_step++
      } else {
        this.current_step = 0
      }
    }
  }
}
</script>


<style scoped lang="scss">
  #constructionsteps{

    .h1{
      position: relative;
      &::before{
        border-bottom: 1px solid #e8fc00;
        content: '';
        position: absolute;
        bottom: -12px;
        right: calc(50% + 3rem);
        left: -1.5rem !important;
      }
    }

    .container{
      background-color: #1B3036;
      border-radius: 45px;
    }

    .steps{
      position: relative;
      transition: all ease .3s;
      /*
      @media(max-width:1200px){
        &{
          transform: translateX(-1rem);
        }
      }*/

      &::before{
        position: absolute;
        top: 2rem;
        bottom: 2rem;
        left: calc(50% - 1px);
        content: '';
        border-left: 2px solid #23C6DB;
      } 

      ul{
        position: relative;
      }

      .step{
        cursor: pointer;
        border: 1px solid #23C6DB;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 24px;
        font-weight: bold;
        color: #23C6DB;
        background-color: #1B3036;
        transition: all ease .3s;
        &:hover{
          background-color: #23C6DB;
          color: #FFF;
        }

        &.active{
          background-color: #23C6DB;
          color: #FFF;
        }
      }
    }



    .descr{
      .h2{
        color: #23C6DB;
      }
      .text{
        line-height: 2;
      }
    }
  }
</style>
