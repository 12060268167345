<template>
  <div id="other-catalog" class="container py-5">
    <div class="row my-4 my-xl-5"  v-for="(v, k) in catalog" :key="k" :data-index="k">
        <div class="col-12">
          <p class="h2 font-weight-normal" v-text="`Производитель ${v.name_mnf}`"></p>
        </div>
        <div class="col-12">
          <OtherCatalogItem :items="v.items"></OtherCatalogItem>
        </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import OtherCatalogItem from './OtherCatalogItem.vue'

export default {
  name: 'Catalog',
  components: {
    OtherCatalogItem
  },
  props: {},
  data: () => {
    return {
      catalog: {},
    }
  },
  methods:{

    get_catalog: function(){

      //const devUrl = `https://composite-pool.ru` // !!!

      //axios.get(devUrl + '/other-catalog/').then((resp)=>{
      axios.get('/other-catalog/').then((resp)=>{
        this.$set(this, 'catalog', resp.data)
      })
    }
  },
  created(){
    this.get_catalog()
  }
}
</script>


<style scoped lang="scss">
  #other-catalog{

      .render_wr{
        position: relative;
        .render{
          cursor: pointer;
        }
        svg{
          position: absolute;
          fill: #E8FC00;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 30%;
          height: 30%;
          transition: opacity .2s linear,transform .2s linear;
          opacity: 0;
          transform: scale(0);
          cursor: pointer;
        }  
        &:hover{
          svg{
            opacity: 1;
            transform: scale(1);            
          }

        }
      }







      @media(max-width:768px){
        .border-right-delimiter{
          position: relative;
          &::after{
            position: absolute;
            left: 0;
            right: 0;
            content: '';
            border-bottom: 1px solid #3F6E7D;
            margin-top: 1.5rem;
            } 
          }  
      }

      @media(min-width:768px) and (max-width:992px){
        .border-right-delimiter{
          position: relative;
          &::after{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            content: '';
            border-left: 1px solid #3F6E7D;
            } 
          } 
        .border-right-delimiter:nth-child(2n){
          position: relative;
          &::after{
            border: none;
            } 
          }   
      }

      @media(min-width:992px){
        .border-right-delimiter{
          position: relative;
          &::after{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            content: '';
            border-left: 2px solid #3F6E7D;
            } 
          } 
        .border-right-delimiter:nth-child(3n+3){
          position: relative;
          &::after{
            border: none;
            } 
          }      
      }

}
</style>
