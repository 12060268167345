<template>
  <div id="modalitem" class="modal fade" tabindex="-1">

    <CoolLightBox v-if="obj.items" :items="[`/assets/Catalog/Pools/${obj.items[active].file}/${color}.png`]" :index="render_index" @close="render_index = null" :loop="true"></CoolLightBox>
    <CoolLightBox v-if="obj.items" :items="photo_gallery.items" :index="photo_gallery.index" @close="photo_gallery.index = null" :loop="true"></CoolLightBox>

    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header" v-if="Object.keys(obj).length">
          <div class="text-left">
            <h2 class="modal-title">
              <!--
              <span class="font-weight-light" v-if="obj.items.length > 1">Бассейны серии</span>
              <span class="font-weight-light" v-else>Бассейн</span>
              <span class="mx-2" v-text="obj.items[0].series_name"></span>
              -->
              <span class="font-weight-light">Выберите цвет бассейна</span>
            </h2>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$parent.modals.modalitem.hide()"></button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 col-xl-5">
              <div class="row">
                <div class="col-12" v-for="(v, k) in obj.items" :key="k">
                  <div class="row text-left mb-3 g-1 item" :class="{'active':active==k}" @click="set_active_model(k)">
                    <div class="col-5">
                      <p class="h5" v-text="v.name"></p>
                      <img class="w-100" :src="`/assets/Catalog/Pools/${v.file}/render_thm.png`" alt="">
                    </div>
                    <div class="col-7">
                      <p class="h5 pl-2" v-text="`${$parent.number_format(v.price)} руб.`"></p>   
                      <div class="d-table pl-2">
                        <div class="d-table-row">
                          <div class="d-table-cell no-wrap small">Длина:</div>
                          <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.length"></span> м.</div>
                        </div>
                        <div class="d-table-row">
                          <div class="d-table-cell no-wrap small">Ширина:</div>
                          <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.width"></span> м.</div>
                        </div>
                        <div class="d-table-row">
                          <div class="d-table-cell no-wrap small">Глубина:</div>
                          <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.depth_start == v.depth_end ? v.depth_end : v.depth_start + ` - ` + v.depth_end"></span> м.</div>
                        </div>
                        <div class="d-table-row">
                          <div class="d-table-cell no-wrap small">Объем:</div>
                          <div class="d-table-cell no-wrap w-100 small pl-2"><span v-text="v.volume"></span> м.<sup>3</sup></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-7" v-if="obj.items">
              <div class="render_wr">
                <img class="w-100 cursor-pointer" :src="`/assets/Catalog/Pools/${obj.items[active].file}/${color}.png`" alt="" @click="render_index = 0">
                <div class="color_name_wr">
                  <span v-text="all_colors[color]"></span>
                </div>
              </div>
              <div class="my-3">
                <span v-for="(cn, ci) in all_colors" :key="ci">
                  <img class="all_colors" :src="`/assets/Catalog/Colors/${ci}.png`" alt="" :title="cn" v-if="obj.items[active][ci] == 1" @click="color = ci" :class="{active: ci == color}">
                </span>                
              </div>
              <div class="my-3" v-if="obj.items[0].cnt_imgs > 0">
                <button class="btn-aqua aqua-shine" @click="open_photo_gallery()">Открыть фотогалерею <br class="d-block d-md-none"> (<span v-text="obj.items[0].cnt_imgs"></span> фото) </button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-around">
            <button class="btn-yellow yellow-shine" @click="$parent.modals.modalitem.hide(); $parent.scroll('quiz'); $emit('choose_pool', obj.items[active])">Собрать свой бассейн</button>
            <button class="btn-yellow yellow-shine" @click="$parent.show_modalorder('Получить консультацию по бассейну ' + obj.items[active].name)">Получить консультацию</button>
            <!-- <button type="button" class="btn-ghost tmt-3" @click="$parent.modals.modalitem.hide()">Закрыть окно</button> -->
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'ModalItem',
  components: {
    CoolLightBox
  },
  props: {
    obj: Object
  },
  data: function(){
    return {
      all_colors: {'BI': 'Blue Iridium', 'BS': 'Blue Stone', 'C': 'Coral', 'E': 'Emerland', 'G': 'Granite', 'NS': 'Night Shine', 'RS': 'Russian Shine', 'SB': 'Sandy Beach', 'SF': 'Sea Foam', 'SFL': 'Sea Foarm Light', 'SS': 'Sky Shine', 'TB': 'Tiffany Blue', '3DW':'3D White', 'BG': 'Black Galaxy', 'NC': 'Без цвета'},
      title: null,
      active: 0,
      color: 'BI',
      render_index: null,
      photo_gallery: {
        items:[],
        index:null
      }
    }
  },
  mounted(){
    this.$parent.modals.modalitem =  new bootstrap.Modal(document.getElementById('modalitem'), { keyboard: false })
  },
  watch:{
    obj: function(n){
      this.active = this.obj.active
      this.color = 'BI'
    }
  },
  methods:{
    set_active_model(index){
      this.active = index // переключаем бассейн
      // если был выбран цвет, которого нет у другой модели - включаем блю иридиум
      if (this.obj.items[this.active][this.color] == 0){
        this.color = 'BI'
      }
    },
    open_photo_gallery(){
      

      this.photo_gallery.items = []
      for (let i = 1; i <= this.obj.items[0].cnt_imgs; i++){
        this.photo_gallery.items.push(`/assets/Catalog/Photogallery/${this.obj.items[0].series_name}/${i}.jpg`)
      }
      this.photo_gallery.index = 0
    }
    /*
    open_gallery: function(file_name, cnt_imgs){
      this.gallery.items = []
      for (let i = 1; i <= cnt_imgs; i++){
        this.gallery.items.push(`/assets/pools/${file_name}/${i}.jpg`)
      }
      this.gallery.items.push(`/assets/pools/${file_name}.png`)
      this.gallery.index = 0
    },
    */
  }
}
</script>


<style scoped lang="scss">
.modal-content{
  background-color: #1B3036;
  margin-bottom: 20px;
  max-width: 100%;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  overflow: visible;
  box-shadow: 0 0 20px 0 #1B3036;
  padding: 10px 16px;
  @media(min-width:992px){
    &{
      padding: 60px 96px;
    }
  }
}

input{
  transition: border-color 0.3s linear;
  box-sizing: border-box;
  width: 100%;
  display: block;
  color: #3b5058;
  margin: 0;
  border: 1px solid #bac3cb;
  border-radius: 100px;
  outline: 0;
  height: 52px;
  line-height: 52px;
  padding: 0 30px;
  background: #fff;
  &.has-error{
    border: 2px solid #fb0000;
  }
  &::placeholder {
    transition: color .3s linear;
    color: #6c919f;
  }
}

.modal-header, .modal-footer{
  border:none;
}

.privacy{
  font-size: 0.75rem;
  color: #69838a;
  line-height: 1;
}

.btn-close {
    outline: none;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #fff;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    &:hover {
      color: #ddd;
      text-decoration: none;
      opacity: .75;
    }
}


.item{
  cursor:pointer;
  transition: all ease .15s;
  &:hover{
    border-radius: 5px;
    background-color: #59b6d31c;
  }
  &.active{
    cursor:default;
    border: 1px solid #76d9f3;
    padding: .5rem;
    border-radius: 5px;
    background-color: #51a9c7;
  }
}

.all_colors{
  width:70px;
  height:70px;
  padding:0.25rem;
  cursor: pointer;
  transition: all ease .3s;
  &.active{
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 8px #51a9c7);
  }
}
</style>
