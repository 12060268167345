<template>
  <div id="modalprivacy" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-scrollable text-dark">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Политика конфиденциальности</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$parent.modals.modalprivacy.hide()"></button>
        </div>
        <div class="modal-body">
          <p>Оставляя свои персональные данные на сайте, Вы соглашаетесь с настоящей Политикой конфиденциальности.</p>
          <p><strong>Термины, используемые в настоящей Политике конфиденциальности:</strong></p>
          <ol>
              <li>Сайт - совокупность логически связанных между собой веб-страниц, а также набор контента, размещаемых в сети «Интернет» по url-адресу: https://composite-pool.ru;</li>
              <li>Персональные данные - любая информация, относящаяся прямо или косвенно к Пользователю Сайта;</li>
              <li>Оператор – администрация Сайта, осуществляющая сбор и обработку персональных данных Пользователей Сайта;</li>
              <li>Пользователь – физическое лицо, являющееся посетителем Сайта и предоставляющее свои персональные данные для их обработки Оператором.</li>
          </ol>
          <p class="text-left"><strong>1. Защита персональных данных</strong></p>
          <p>Оператор не может передать или раскрыть персональные данные, предоставленные Пользователем при использовании функций сайта третьим лицам, кроме случаев, описанных законодательством РФ.</p>
          <p class="text-left"><strong>2. Получение персональных данных</strong></p>
          <p>Оператор использует следующий алгоритм сбора персональных данных Пользователя: нажимая на кнопки "Оставить заявку, обратный звонок или пройти техническое обслуживание", расположенную в разных частях сайта, открывается форма заявки, в которой Пользователю предлагается указать контактный номер своего телефона, а также имя. Нажимая кнопку «Отправить», Пользователь дает свое согласие на обработку Оператором своих персональных данных, согласно Федеральному закону "О персональных данных" от 27.07.2006 N 152-ФЗ.</p>
          <p class="text-left"><strong>3. Использование персональных данных</strong></p>
          <p>Сайт использует персональные данные Пользователя для обслуживания и для улучшения качества предоставляемых услуг. Персональные данные могут быть раскрыты в случаях, описанных законодательством, либо, когда администрация сочтет подобные действия необходимыми для соблюдения юридической процедуры, судебного распоряжения или легального процесса необходимого для работы Пользователя с Сайтом. В других случаях, ни при каких условиях, информация, которую Пользователь передает Сайту, не будет раскрыта третьим лицам.</p>
          <p class="text-left"><strong>4. Коммуникация Оператора с Пользователем</strong></p>
          <p>После того, как Пользователь оставил свои персональные данные, Оператор имеет право совершать звонки по номеру телефона, которые указал Пользователь, с целью обслуживания и с целью улучшения качества предоставляемых услуг.</p>
          <p class="text-left"><strong>5. Безопасность персональных данных</strong></p>
          <p>Сайт обеспечивает безопасность персональных данных от несанкционированного использования их третьими лицами.</p>
          <p class="text-left"><strong>6. Получение информации Пользователем об обработке Оператором его персональных данных. Отзыв согласия на обработку персональных данных.</strong></p>
          <p>Согласно п. 7 ст. 14 Федерального закона "О персональных данных" от 27.07.2006 N 152-ФЗ., Пользователь имеет право на получение информации, касающейся обработки его персональных данных. Согласно п. 2 ст. 15 Федерального закона "О персональных данных" от 27.07.2006 N 152-ФЗ., Оператор обязан прекратить обработку персональных данных Пользователя по его требованию.</p>
          <p class="text-left"><strong>7. Уведомления об изменениях в настоящей Политике</strong></p>
          <p>Оператор оставляет за собой право вносить изменения в Политику конфиденциальности без дополнительных уведомлений. Нововведения вступают в силу с момента их опубликования. Пользователь может отслеживать изменения в Политике конфиденциальности самостоятельно.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$parent.modals.modalprivacy.hide()">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: 'ModalPrivacy',
  props: {
    branch: String
  },
  mounted(){
    this.$parent.modals.modalprivacy =  new bootstrap.Modal(document.getElementById('modalprivacy'), { keyboard: false })
    //this.$parent.modals.modalprivacy.show()
  }
}
</script>


<style scoped lang="scss">
.btn-close {
    outline: none;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    &:hover {
      color: #000;
      text-decoration: none;
      opacity: .75;
    }
}
</style>
