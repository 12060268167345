<template>
  <div id="menu" @click="$emit('open_menu', false)">

    <transition :duration="150" name="show">
      <div class="gwr d-flex flex-column shadow-lg" v-if="opened_menu">
        <ul class="p-5 flex-grow-1">
          <li><a href="#" @click="$parent.scroll('header')">Главная</a></li>
          <li><a href="#" @click="$parent.scroll('catalog')">Каталог</a></li>
          <li><a href="#" @click="$parent.scroll('constructionsteps')">Монтаж</a></li>
          <li><a href="#" @click="$parent.scroll('quiz')">Подбор бассейна</a></li>
          <li><a href="#" @click="$parent.scroll('advantages')">Наши преимущества</a></li>
          <li><a href="#" @click="$parent.scroll('photogallery')">Галерея</a></li>
          <li><a href="#" @click="$parent.scroll('certs')">Сертификаты</a></li>
          <li><a href="#" @click="$parent.scroll('contacts')">Контакты</a></li>
        </ul>
        <div class="contacts p-5">
          <p class="m-0">
            <a class="mr-4 text-white" :href="`tel:${phone}`" v-text="phone"></a>
          </p>
          <p class="m-0">
            <a class="mr-4 text-link-color" :href="`mailto:${email}`" v-text="email"></a>
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    opened_menu: Boolean,
    email: String,
    phone: String,
  }
}
</script>


<style scoped lang="scss">
  #menu{
  position: fixed !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #0000004a;
  z-index: 1;
  .gwr{
      height: 100%;
      width: 100%;
      max-width: 420px;
      background: linear-gradient(to right,#08242f 0%,#3a5a5f 100%);
      ul{
        list-style: none;
        li{
          font-size: 1.2rem;
          margin-top: 1rem;
          a{
            color: #fff;
            text-decoration: none;

          }
        }
      }
      .contacts{
        a{
            color: #fff;
            text-decoration: none;
        }
      }
    }
  }
</style>
